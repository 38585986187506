import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"

export default ({data, location}) => {
  const meta = {
    title: "About",
    description: "The story behind Speaking of Products and it's producer, Dan Miller.",
    publicationDate: `2020-02-01`,
    lastUpdated: `2020-02-01`,
  }
  return(
    <Layout 
    title={meta.title}
    description={meta.description}
    pathname={location.pathname}
    publicationDate={meta.publicationDate}
    lastUpdated={meta.lastUpdated}>
    <div className="px-12 py-10 md:py-8">
      <div className="md:max-w-6xl md:mx-auto">
        <div className="pb-4">
          <h3>About</h3>
          <h2>{meta.description}</h2>
        </div>
        <div className="px-4">
          <section>
            <div className="w-1/3 float-right mb-2">
            <Img 
              fluid={data.file.childImageSharp.fluid}
              alt="Dan Miller"
            />
            </div>
            <p>Hi there and welcome to Speaking of Products, the site that helps people who are looking to start or grow their own product-based business.
            </p>
            <p>After years of running my own companies providing services in tech product development, tech project management and general business admin, I decided to pivot and focus on products.
            </p>
            <p>I launched Speaking of Products in 2020. For years up to that point, I had been listening-in and following-on with the stories, blogs and podcasts created by folks in the self-funded startup (a.k.a. bootstrap) space. I wanted to join in as well as give something back.
            </p>
            <p>The goal of Speaking of Products is to be a source of ideas, experiences, resources and community for people looking to start or grow a product-based business.
            </p>
            <p>Come along for the ride as I take the journey of starting and growing a product-based business.
            </p>
            <p>You'll hear about the thinking behind my decisions, the challenges I face, what it takes to make it a reality, the highs, the lows and the lessons-learned
            </p>
          </section>
          <hr className="my-8" />
          <section>
            <p>Speaking of Products is produced by Dan Miller, lover of people creating awesome stuff, on an adventure to encourage and share solutions that make the world a better place.
            </p>
            <p>Dan is the founder of several ventures including <a href="http://citadelsystems.com.au/">Citadel Systems</a>, a technology project management firm, and <a href="http://tradeguardian.com.au/">Trade Guardian</a>, a business administration outsourcing provider.
            </p>
            <p>Dan’s experience and skills are supported by a foundation of formal education in business and technology including BEc, BIT, MSEng and MBA. Dan also spent several years teaching at the Australian National University as a Tutor and Adjunct Lecturer in both the Department of Computer Science and the College of Business and Economics.
            </p>
            <p>There are a few simple philosophies that Dan abides by…</p>
            <blockquote className="mb-10">
              “Have fun in everything you do, continuously improve by challenging yourself to bigger and better things, learn-by-doing, help others to improve themselves, and give back at least as much as the world provides you”.
            </blockquote>
          </section>
        </div>
      </div>
    </div>
  </Layout>
  )
}

export const pageQuery = graphql`
query AboutPageImage {
  file(
    relativePath: {eq: "Miller_20170913_00004.jpg" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`